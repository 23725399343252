import {Pipe, PipeTransform} from '@angular/core';
import { pipe } from 'rxjs';
import { DatePipe } from '@angular/common';

@Pipe({
    name:'datex'
})

export class DatexPipe implements PipeTransform{    
    transform(value:any,format:string=""):string{
       if(value){
          return "";
       }
       else
       return "";
    }
}