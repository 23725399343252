export class ClientProfileModel {
    enc_ClientID: string;
    enc_LoginID: string;
    firstName: string;
    ssn: string;
    lastName: string;
    country_Efile: string;
    country_Efile_india: string;
    greenCard: string;
    dob: any;
    occupation: string;
    maritalStatus: string;
    street: string;
    city: string;
    state: string;
    countryofResidence: string;
    countryofCitizenship: string;
    street_India: string;
    city_India: string;
    state_India: string;
    pincode_India: string;
    hasFinancialInterstOrSignatureAuthority: string;
    balanceExceedingLimit: string;
    sFirstName: string;
    s_ssn: string;
    sLastName: string;
    sCountryOfCitizenship: string;
    sCountryOfSecondaryCitizenship: string;
    sdob: any;
    sOccupation: string;
    zipCode: string;
    pemail: string;
    semail: string;
    pphone: string;
    sphone: string;
    year_Of_Filing: string;
    noOfDependent: number;
    createdDate: Date;
    createdBy: number;
    modifiedDate: Date;
    modifiedBy: number;
    isCompleted: boolean;
    isSubmited: boolean;
    isCorpClient: boolean;
    isnr: boolean;
    xDays: number;
    yDays: number;
    zDays: number;
    isSSNorITIN: string;
    s_isSSNorITIN: string;
    utaxID: string;
    Corpname: string;
    gstin: string;
    serviceID: number;
    gaClientID: string;
    referenceID: any;
    advisorUnreadCount: number;
    clientUnreadCount: number;
    sHasGreenCard: string;

    constructor() {
        this.enc_ClientID = '';
        this.enc_LoginID = '';
        this.firstName = '';
        this.ssn = '';
        this.lastName = '';
        this.country_Efile = '';
        this.country_Efile_india = '';
        this.greenCard = '';
        this.dob = '';
        this.occupation = '';
        this.maritalStatus = '';
        this.street = '';
        this.city = '';
        this.state = '';
        this.countryofResidence = '';
        this.countryofCitizenship = '';
        this.street_India = '';
        this.city_India = '';
        this.state_India = '';
        this.pincode_India = '';
        this.hasFinancialInterstOrSignatureAuthority = '';
        this.balanceExceedingLimit = '';
        this.sFirstName = '';
        this.s_ssn = '';
        this.sLastName = '';
        this.sCountryOfCitizenship = '';
        this.sCountryOfSecondaryCitizenship = '';
        this.sdob = '';
        this.sOccupation = '';
        this.zipCode = '';
        this.pemail = '';
        this.semail = '';
        this.pphone = '';
        this.sphone = '';
        this.year_Of_Filing = '';
        this.noOfDependent = 0;
        this.createdDate = new Date();
        this.createdBy = 0;
        this.modifiedDate = new Date();
        this.modifiedBy = 0;
        this.isCompleted = false;
        this.isSubmited = false;
        this.isCorpClient = false;
        this.isnr = false;
        this.xDays = 0;
        this.yDays = 0;
        this.zDays = 0;
        this.isSSNorITIN = 'Applied';
        this.s_isSSNorITIN = 'Applied';
        this.utaxID = '';
        this.Corpname = '';
        this.gstin = '';
        this.serviceID = 0;
        this.gaClientID = '';
        this.referenceID = 0;
        this.advisorUnreadCount = 0;
        this.clientUnreadCount = 0;
    }
}