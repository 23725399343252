import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseurlService } from 'src/app/CommonService/baseurl.service';
import { ResponseModel } from 'src/app/Core/Models/ResponseModel';
import { Response } from 'selenium-webdriver/http';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  
  isAdavance:boolean=false; 

  constructor(private httpClient: HttpClient, private baseUrlService: BaseurlService) { 
  }
  getExchangeRate() {
    return this.httpClient.get<ResponseModel>(
      this.baseUrlService.baseUrl + 'Payment/GetExchangeRate'
    )
  }

  getPaymentList() {
    return this.httpClient
      .get<ResponseModel>(
        this.baseUrlService.baseUrl + 'Payment/getAllPaymentList'
        , { "headers": { "content-type": "application/json" } }
      )
  }

  IUGroupPayment_OS2(data) {
    return this.httpClient
      .post<ResponseModel>(
        this.baseUrlService.baseUrl + 'Payment/IUGroupPayment_OS2', data
        , { "headers": { "content-type": "application/json" } }
      )
  }

  GetTransactionDetails(id) {
    return this.httpClient
      .get<ResponseModel>(
        this.baseUrlService.baseUrl + 'Payment/GetTransactionDetails?Enc_ClientID=' + id
        , { "headers": { "content-type": "application/json" } }
      )
  }

  GetPaymentDetails(id) {
    return this.httpClient
      .get<ResponseModel>(
        this.baseUrlService.baseUrl + 'Payment/GetTransactionDetails?Enc_ClientID=' + id
        , { "headers": { "content-type": "application/json" } }
      )
  }

}
