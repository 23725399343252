import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class BaseurlService {
  tokenUrl = "";
  baseUrl: string;
  host: string;
  constructor() {
    this.host = window.location.host;
    if (this.host.indexOf("localhost") > -1) {
      this.baseUrl = "http://localhost:51170/api/";
      this.tokenUrl = "http://localhost:51170/Token";
    } else {
      this.baseUrl = "https://" + this.host + "/api/api/";
      this.tokenUrl = "https://" + this.host + "/api/Token";
    }
  }
}
