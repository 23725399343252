import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../Services/login.service';
import { Router } from '@angular/router';
import { AppComponent } from '../../app.component';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-migration',
  templateUrl: './migration.component.html',
  styleUrls: ['./migration.component.css']
})
export class MigrationComponent implements OnInit {

  constructor(private route:Router,private loginService:LoginService
    ,private toastrService:ToastrService
    ,private appComponent:AppComponent) { }

  ngOnInit() {
    this.login();
  }

  
  login(){
    this.appComponent.isLoading=true;
    this.loginService.Login("","",false,true).subscribe(result=>{
      this.appComponent.isLoading=false;
      if(result){
        localStorage.setItem('session',result.access_token);
        this.route.navigate(['dash']);
      }
      else{
        this.toastrService.error('Something went wrong');
      }
    },(error:HttpErrorResponse)=>{
      this.appComponent.isLoading=false;

      if(!error.status){
        this.toastrService.error('Invalid Credentials !');
        let host=window.location.host;
        if (host.indexOf('-qa') > -1) {
          document.location.href = 'https://ustaxfiling.in'
        }
        else {
          document.location.href = 'https://ustaxfiling.in'
          
        }
      }
    });    
  }
}
