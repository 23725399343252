import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { ITokenResponse } from "../Models/ITokenResponse";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { BaseurlService } from "src/app/CommonService/baseurl.service";
import { ResponseModel } from "../Models/ResponseModel";

@Injectable()
export class LoginService {
  constructor(private http: HttpClient, private baseUrl: BaseurlService) {}

  Login(un: string, pw: string, isEncrypted: boolean, isMigration: boolean) {
    return this.http
      .post<ITokenResponse>(
        this.baseUrl.tokenUrl,
        "grant_type=password&username=" +
          un +
          "&password=" +
          pw +
          "&isCorporate=true&isEncrypted=" +
          isEncrypted +
          "&isMigration=" +
          isMigration,
        { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          let transformedError = this.transformError(err);
          return Observable.throw(transformedError);
        })
      );
  }

  resetPassword(email: string) {
    return this.http
      .get<any>(this.baseUrl.baseUrl + "Account/ResetPassword?email=" + email, {
        headers: { "Content-Type": "application/json" },
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          let transformedError = this.transformError(err);
          return Observable.throw(transformedError);
        })
      );
  }

  logout() {
    return this.http.get<ResponseModel>(
      this.baseUrl.baseUrl + "Account/Logout"
    );
  }

  transformError(err) {
    switch (err.status) {
      case 400:
        return {
          status: err.status,
          message: "Oops, it looks like that resource was not found",
        };
    }
  }
}
