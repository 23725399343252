import { Component, OnInit, TemplateRef } from "@angular/core";
import { Router } from "@angular/router";
import { LoginService } from "../Services/login.service";
import { ToastrService } from "ngx-toastr";
import { HttpErrorResponse } from "@angular/common/http";
import { AppComponent } from "src/app/app.component";
import { BsModalRef, BsModalService } from "ngx-bootstrap";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  usernameText: string;
  resetPasswordEmail: string;
  passwordText: string;
  isResetClicked = false;

  modalRef: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
  };

  constructor(
    private route: Router,
    private loginService: LoginService,
    private toastrService: ToastrService,
    private appComponent: AppComponent,
    private modalService: BsModalService
  ) {}

  ngOnInit() {}

  login() {
    this.appComponent.isLoading = true;
    this.loginService
      .Login(this.usernameText, this.passwordText, false, true)
      .subscribe(
        (result) => {
          this.appComponent.isLoading = false;
          if (result) {
            localStorage.setItem("session", result.access_token);
            this.route.navigate(["dash"]);
          } else {
            this.toastrService.error("Something went wrong");
          }
        },
        (error: HttpErrorResponse) => {
          this.appComponent.isLoading = false;
          if (!error.status) {
            this.toastrService.error("Invalid Credentials !");
          }
        }
      );
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
  }

  resetPassword() {
    this.loginService.resetPassword(this.resetPasswordEmail).subscribe(
      (res) => {
        if (res.isSuccess) {
          this.modalRef.hide();
        }
        alert(res.message);
      },
      (error) => {
        alert(error.Message);
      }
    );
  }
}
