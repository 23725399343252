 import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from "@angular/common";
import { CoreRoutingModule } from './core.routing.module'
import { RouterModule } from '@angular/router'
import { LoginComponent } from './login/login.component';
import { MigrationComponent } from './migration/migration.component';
import { LayoutComponent } from './layout/layout.component';
import { LoginService } from './Services/login.service';
import {HttpClientModule} from '@angular/common/http';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PaymentService } from '../Payment/Services/payment.service';


@NgModule({
    imports: [BrowserModule,CommonModule, CoreRoutingModule,FormsModule,RouterModule, ReactiveFormsModule,HttpClientModule],
    declarations: [LoginComponent, MigrationComponent,PageNotFoundComponent],
    exports: [BrowserModule, ReactiveFormsModule],
    providers: [LoginService,PaymentService]
})

export class CoreModule { }