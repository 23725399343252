import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { LoginComponent } from './login/login.component'
import { MigrationComponent } from './migration/migration.component'
import { LayoutComponent } from './layout/layout.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

const routes: Routes = [
    { path: '', pathMatch: 'full', redirectTo: 'login' },
    { path: 'login', component: LoginComponent },
    { path: 'migration', component: MigrationComponent },
    { path: 'dash', loadChildren: '../Dashboard/dashboard.module#DashboardModule' },
    { path: 'payment', loadChildren: '../Payment/payment.module#PaymentModule' },
    { path: 'profile', loadChildren: '../Profile/Profile.module#ProfileModule' },
    { path: '**', component: PageNotFoundComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})

export class CoreRoutingModule { }
