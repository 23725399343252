import { BrowserModule } from "@angular/platform-browser";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { CoreModule } from "./Core/core.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { AuthInterceptorService } from "./CommonService/auth-interceptor.service";
import {
  ToastrModule,
  ToastNoAnimation,
  ToastNoAnimationModule,
} from "ngx-toastr";
import { ConfirmationPopoverModule } from "angular-confirmation-popover";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { ProfileService } from "./Profile/Services/profile.service";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { CustomDateFormtter } from "./Pipes/CustomDateFormtter";
import { ConfirmationModalComponent } from "./Modals/confirmation-modal/confirmation-modal.component";
import { DatexPipe } from "./Pipes/DatePipe";
import { ModalModule } from "ngx-bootstrap";

@NgModule({
  declarations: [AppComponent, ConfirmationModalComponent, DatexPipe],
  imports: [
    CommonModule,
    RouterModule,
    BrowserModule,
    FormsModule,
    CoreModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    ToastrModule.forRoot({
      toastComponent: ToastNoAnimation,
    }),
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: "danger", // set defaults here
    }),
    ToastNoAnimationModule,
    ConfirmationPopoverModule,
    ModalModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    ProfileService,
    CustomDateFormtter,
  ],
  exports: [BrowserModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
