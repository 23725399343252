import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResponseModel } from 'src/app/Core/Models/ResponseModel';
import { BaseurlService } from 'src/app/CommonService/baseurl.service';
import { CountryListModel } from '../Models/countryListModel';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { ClientProfileModel } from '../Models/ClientProfileModel';
import { AdvisorProfileModel } from 'src/app/Core/Models/AdvisorProfileModel';
import { DependentInfoModel } from '../Models/DependentInfoModel';
import { NRQuestionModel } from '../Models/NRQuestionModel';
import { IncomeInfoModel } from '../Models/IncomeInfoModel';
import { TaxSituationModel } from '../Models/TaxSituationModel';
import { StatusInfoModel } from '../Models/StatusInfoModel';
import { FileAttachmentModel } from '../Models/FileAttachmentModel';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  clientProfileInfo: ClientProfileModel;
  advisorProfile: AdvisorProfileModel;
  _unreadMessageCount: number = 0;
  private subjectCountryList: Subject<any>;
  private subjectClientProfile: Subject<any>;
  private subjectAdvisorProfile: Subject<any>;
  countryList: CountryListModel[];
  IsHistory: boolean = false;

  constructor(private httpClient: HttpClient, private baseUrlService: BaseurlService) {

    this.clientProfileInfo = new ClientProfileModel();
    this.subjectCountryList = new Subject<any>();
    this.subjectClientProfile = new Subject<ClientProfileModel>();
    this.subjectAdvisorProfile = new Subject<AdvisorProfileModel>();
    this.countryList = [];
  }

  afterAdvisorProfileLoad(advisorProfile: AdvisorProfileModel) {
    this.advisorProfile = advisorProfile;
    this.subjectAdvisorProfile.next(advisorProfile);
  }

  getAdvisorProfileFromMyInfo(): Observable<AdvisorProfileModel> {
    return this.subjectAdvisorProfile.asObservable();
  }

  afterCountryListLoad(countryList: CountryListModel[]) {
    this.countryList = countryList;
    this.subjectCountryList.next(countryList);
  }

  getCountryListFromMyInfo(): Observable<CountryListModel[]> {
    return this.subjectCountryList.asObservable();
  }

  afterClientProfileLoad(ClientProfile: ClientProfileModel) {
    debugger;
    ClientProfile.isSSNorITIN=ClientProfile.isSSNorITIN==''?'Applied':ClientProfile.isSSNorITIN;
    ClientProfile.s_isSSNorITIN=ClientProfile.s_isSSNorITIN==''?'Applied':ClientProfile.s_isSSNorITIN;
    this.clientProfileInfo = ClientProfile;

      if (this.clientProfileInfo.dob == '0001-01-01T00:00:00')
        this.clientProfileInfo.dob = '';

      if (this.clientProfileInfo.sdob == '0001-01-01T00:00:00')
        this.clientProfileInfo.sdob = '';

    this.subjectClientProfile.next(ClientProfile);
  }

  setAdvisorId(AdvisorProfile: string) {
    localStorage.removeItem('atd');
    let encrypted_cid = btoa(AdvisorProfile);
    localStorage.setItem('atd', encrypted_cid);
  }

  getAdvisorId(): string {
    var ctd: string = undefined;
    let encrypted_id = localStorage.getItem('atd');
    if (encrypted_id && encrypted_id != '') {
      ctd = atob(encrypted_id);
    }
    return ctd;
  }

  setClientId(clientId: string) {
    localStorage.removeItem('ctd');
    // let encrypted_cid = atob(clientId);
    let encrypted_cid = (clientId);
    localStorage.setItem('ctd', encrypted_cid);
  }

  getClientId(): string {
    var ctd: string = undefined;
    let encrypted_id = localStorage.getItem('ctd');
    if (encrypted_id && encrypted_id != '') {
      // ctd = btoa(encrypted_id);
      ctd = (encrypted_id);
    }
    return ctd;
  }

   getCookie(name: string) {
    let ca: Array<string> = document.cookie.split(';');
    let caLen: number = ca.length;
    let cookieName = `${name}=`;
    let c: string;

    for (let i: number = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }
 

  getClientProfileFromMyInfo(): Observable<ClientProfileModel> {
    return this.subjectClientProfile.asObservable();
  }

  getCountryList() {
    return this.httpClient
      .get<ResponseModel>(
        this.baseUrlService.baseUrl + 'Common/GetCountryNames'
      )
  }

  GetGADetails() {
    return this.httpClient
      .get<ResponseModel>(
        this.baseUrlService.baseUrl + 'Client/GetGADetails'
      )
  }

  getStateList() {
    return this.httpClient
      .get<ResponseModel>(
        this.baseUrlService.baseUrl + 'Common/GetStates'
      )
  }

  getClientInfo(ClientInfo: ClientProfileModel) {
    return this.httpClient
      .post<ResponseModel>(
        this.baseUrlService.baseUrl + 'Client/getClientInfo',
        ClientInfo
      );
  }

  getDependentInfo(enc_ClinetID: string) {
    return this.httpClient
      .get<ResponseModel>(
        this.baseUrlService.baseUrl + 'Dependent/getDependentInfo/?enc_ClientID=' + enc_ClinetID
      )
  }

  saveAllClientProfile() {
    if (this.clientProfileInfo.ssn === 'NNN-NN-NNNN') {
      this.clientProfileInfo.ssn = '';
    }
    return this.httpClient
      .post<ResponseModel>(
        this.baseUrlService.baseUrl + 'Client/SetClientInfoDetails',
        this.clientProfileInfo
      );
  }

  getAdvisorProfile(enc_ClientID: string) {
    return this.httpClient
      .get<ResponseModel>(
        this.baseUrlService.baseUrl + 'Admin/GetAdvisorProfile/?enc_ClientID=' + enc_ClientID
      );
  }

  setDependentInfo(dependentInfo: DependentInfoModel) {
    return this.httpClient
      .post<ResponseModel>(
        this.baseUrlService.baseUrl + 'Dependent/setDependentInfo',
        dependentInfo
      );
  }

  getClientChat(id: string) {
    return this.httpClient
      .get<ResponseModel>(
        this.baseUrlService.baseUrl + 'Message/getClientChat?Enc_ClientID=' + id
      )
  }
  sendMessage(obj: any) {
    return this.httpClient.post<ResponseModel>(this.baseUrlService.baseUrl + 'Message/IOUCLientChat', obj, { "headers": { "content-type": "application/json" } })
  }

  SetSubmitionDetails() {
    return this.httpClient
      .get<ResponseModel>(
        this.baseUrlService.baseUrl + 'Client/SetSubmitionDetails/?Enc_ClientID=' + this.clientProfileInfo.enc_ClientID, { "headers": { "content-type": "application/json" } }
      )
  }
  getNRDetails(enc_ClientID: string) {
    return this.httpClient
      .get<ResponseModel>(
        this.baseUrlService.baseUrl + 'NR/GetNRDetails?enc_ClientID=' + enc_ClientID
      )
  }

  SetNRQuestion(nrQuestion: NRQuestionModel) {
    return this.httpClient
      .post<ResponseModel>(
        this.baseUrlService.baseUrl + 'NR/SetNRQuestion'
        , nrQuestion
      )
  }

  setIncomeDetails(IncomeInfo: IncomeInfoModel) {
    return this.httpClient
      .post<ResponseModel>(
        this.baseUrlService.baseUrl + 'Income/SetIncomeDetails'
        , IncomeInfo
      )
  }

  setPropertyDetails(PropertyList) {
    return this.httpClient
      .post<ResponseModel>(
        this.baseUrlService.baseUrl + 'Income/SetPropertyDetails'
        , PropertyList
      )
  }

  getIncomeDetails(enc_ClientID: string) {
    return this.httpClient
      .get<ResponseModel>(
        this.baseUrlService.baseUrl + 'Income/GetIncomeDetails?enc_ClientID=' + enc_ClientID
      )
  }

  getPropertyDetails(enc_ClientID: string) {
    return this.httpClient.get<ResponseModel>(
      this.baseUrlService.baseUrl + 'Income/GetPropertyDetails?enc_ClientID=' + enc_ClientID)
  }

  saveTaxSituation(taxSituation: TaxSituationModel) {
    return this.httpClient
      .post<ResponseModel>(
        this.baseUrlService.baseUrl + 'TaxSituation/IUTaxSituation'
        , taxSituation
      )
  }

  getTaxSituation(enc_ClientID: string) {
    return this.httpClient
      .get<ResponseModel>(
        this.baseUrlService.baseUrl + 'TaxSituation/GetTaxSituation?enc_ClientID=' + enc_ClientID
      )
  }

  GetDocumentList(enc_ClientID: string) {
    return this.httpClient
      .get<ResponseModel>(
        this.baseUrlService.baseUrl + 'Documents/GetFileList?Enc_ClientID=' + enc_ClientID + "&IncomePageIndex=1", { "headers": { "content-type": "application/json" } })
  }

  DownloadDocuments(enc_Client: string, id: string) {
    // return this.httpClient
    //   .get<ResponseModel>(
    //     this.baseUrlService.baseUrl + 'Documents/DownloadFile?Enc_ClientID=' + enc_Client + '&Enc_FileID=' + id, { "headers": { "content-type": "application/json" } })
    window.open(this.baseUrlService.baseUrl + 'Documents/DownloadFile?Enc_ClientID=' + enc_Client + '&Enc_FileID=' + id);
  }
  GetHrbTaxDocumentList(enc_ClientID: string) {
    return this.httpClient
      .get<ResponseModel>(
        this.baseUrlService.baseUrl + 'Documents/GetClientTaxreturnFiles?Enc_ClientID=' + enc_ClientID, { "headers": { "content-type": "application/json" } })
  }

  DownloadTaxReturn(EncClientID: string, encFileID: string) {
    // return this.httpClient
    //   .post<ResponseModel>(
    //     this.baseUrlService.baseUrl + 'Documents/Download1040TaxReturn?EncClientID=' + EncClientID + '&encFileID=' + encFileID
    //     , ''
    //   )

    window.open(this.baseUrlService.baseUrl + 'Documents/Download1040TaxReturn?EncClientID=' + EncClientID + '&encFileID=' + encFileID);

  }
  getIsFeePaid(encClientID: string) {
    return this.httpClient
      .get<ResponseModel>(
        this.baseUrlService.baseUrl + 'Client/getIsFeePaid?Enc_ClientID=' + encClientID
      )
  }
  InsertStatusInfo(StatusInfo: StatusInfoModel) {
    return this.httpClient.post<ResponseModel>(this.baseUrlService.baseUrl + 'Client/InsertStatusInfo'
      , StatusInfo
    );
  }
  ApproveDocs(FileList: FileAttachmentModel[]) {
    return this.httpClient.post<ResponseModel>(this.baseUrlService.baseUrl + 'Client/ApproveDocs'
      , FileList
    );
  }

  setCurrentYear(YearOfFiling: string) {
    localStorage.removeItem('cy');
    let encrypted_cy = atob(YearOfFiling);
    localStorage.setItem('cy', encrypted_cy);
  }

  getCurrentYear() {
    var cyear: string = undefined;
    let encrypted_cyear = localStorage.getItem('cy');
    if (encrypted_cyear && encrypted_cyear != '') {
      cyear = btoa(encrypted_cyear);
    }
    return cyear;
  }

  DocumentQueryByClient(AdvisorEmail: string, AdvisorName: string, ClientName: string, txtQuery: string) {
    return this.httpClient
      .get<ResponseModel>(
        this.baseUrlService.baseUrl + 'Email/DocumentQueryByClient?AdvisorEmail=' + AdvisorEmail + '&AdvisorName=' + AdvisorName + '&ClientName=' + ClientName + '&txtQuery=' + txtQuery

      )
  }
  
  DocumentsApprovedByClient(AdvisorEmail: string, AdvisorName: string, ClientName: string) {
    return this.httpClient
      .get<ResponseModel>(
        this.baseUrlService.baseUrl + 'Email/DocumentsApprovedByClient?AdvisorEmail=' + AdvisorEmail + '&AdvisorName=' + AdvisorName + '&ClientName=' + ClientName

      )
  }
  getClientStatus(encClientID: string) {
    return this.httpClient
      .get<ResponseModel>(
        this.baseUrlService.baseUrl + 'Client/getClientStatus?Enc_ClientID=' + encClientID
      )
  }

  getClientTaxCondition(enc_ClientID: string) {
    return this.httpClient
      .get<ResponseModel>(
        this.baseUrlService.baseUrl + 'TaxSituation/GetTaxCondition/?encid=' + enc_ClientID
      )
  }

  getClientCurrentStatus(encClientID: string) {
    return this.httpClient.get<ResponseModel>(
      this.baseUrlService.baseUrl + 'Client/GetClientCurrentStatus?EncClientID=' + encClientID
    )
  }

  updateMenuID(menuID, EncClientID) {
    return this.httpClient.get<ResponseModel>(
      this.baseUrlService.baseUrl + 'Client/UpdateMenuID?EncClientID=' + EncClientID + "&MenuID=" + menuID
    )
  }

  getMessageUnreadCount(id: string) {
    return this.httpClient
      .get<ResponseModel>(
        this.baseUrlService.baseUrl + 'Message/getMessageUnreadCount?Enc_ClientID=' + id
        , { "headers": { "content-type": "application/json" } }
      )
  }

  deleteDependent(enc_ClientID: string, enc_AutoID: string): any {
    return this.httpClient
      .get<ResponseModel>(
        this.baseUrlService.baseUrl + 'Dependent/deleteDependent?Enc_ClientID=' + enc_ClientID + '&Enc_AutoID=' + enc_AutoID
        , { "headers": { "content-type": "application/json" } }
      )
  }

  getDependent(cid: string, did: string) {
    return this.httpClient
      .get<ResponseModel>(
        this.baseUrlService.baseUrl + 'Dependent/getDependent?Enc_ClientID=' + cid + '&Enc_AutoID=' + did
        , { "headers": { "content-type": "application/json" } }
      )
  }

  GetDeductionInfo(Enc_ClientID: string) {
    return this.httpClient
      .get<ResponseModel>(
        this.baseUrlService.baseUrl + 'Deduction/GetDeductionDetails?Enc_ClientID=' + Enc_ClientID
      );
  }

  GetDeductionDocumentList(enc_ClientID: string) {
    return this.httpClient
      .get<ResponseModel>(
        this.baseUrlService.baseUrl + 'Documents/GetFileList?Enc_ClientID=' + enc_ClientID + "&IncomePageIndex=9", { "headers": { "content-type": "application/json" } })
  }

  ClientAssignedToKnownAdvisor(ClientInfo, AdvisorInfo) {
    return this.httpClient.post<ResponseModel>(
      this.baseUrlService.baseUrl + 'Email/ClientAssignedKnownAdvisor',
     {ClientInfo:ClientInfo, AdvisorInfo:AdvisorInfo})
  }
}