import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';
import { Observable } from 'rxjs'
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';


@Injectable()

export class AuthInterceptorService {

  constructor(private _route: Router) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // let token = this.getToken();
    if (!(request.url.indexOf('Token') > -1)) {
      const newReq = request.clone({
        withCredentials: true
        // setHeaders: {
        //   Authorization: `Bearer ${this.getToken()}`
        // }
      });
      // return next.handle(newReq).pipe(catchError((e:any)=>observable.throw(this.handleError(e))
      return next.handle(newReq).pipe(catchError((e: any) => Observable.throw(this.handleEror(e))));
    } else {
      const newReq = request.clone({
        withCredentials: true
      });
      return next.handle(newReq).pipe(catchError((e: any) => Observable.throw(this.handleEror(e))));
    }
  }

  private handleEror(error: HttpErrorResponse) {
    if (error.status === 401) {
      //localStorage.setItem('session', null);
      // tslint:disable-next-line: prefer-const
      let copy = window.location.host;
      // if (copy.indexOf('localhost') == -1 && copy.indexOf('app.ustaxfiling.in') === -1) {
      //   //window.location.href =  'https://' + copy;//'https://app.ustaxfiling.in';
      // }      else {
      //   this._route.navigateByUrl('/login');
      // }
    }
  }

  getToken() {
    return localStorage.getItem('session');
  }

}
