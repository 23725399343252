import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  isLoading:boolean;
  title = 'USTO-WebAPP';

  constructor(){
    this.isLoading=false;
  }
}