
// import { NgbDateParserFormatter, Da } from 'ngx-bootstrap';
import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable(

)

export class CustomDateFormtter {
    datePipe = new DatePipe('en-IN');
    dateFormatString: string = 'yyyy-MM-dd'
    constructor(
    ) {
        // super();
    }
    format(date: any): string {
        if (date === null) {
            return '';
        }
        try {
            if (date instanceof Date) {
                return this.datePipe.transform(new Date(date.getFullYear(), date.getMonth(), date.getDate()), this.dateFormatString);
            }            
            else
            return date;
        } catch (e) {
            return '';
        }
    }
    parse(value: any): Date {
        let returnVal: Date;
        if (!value) {
            returnVal = null;
        } else {
            try {
                if (value instanceof Date) {
                    value = this.datePipe.transform(value, this.dateFormatString);
                }
                var date=value.split('T');
                date = date[0].split('-');
                returnVal = new Date(parseInt(date[0]), parseInt(date[1]) - 1, parseInt(date[2]), 0, 0, 0, 0);
                // returnVal = new Date(this.datePipe.transform(value, this.dateFormatString));

            } catch (e) {
                returnVal = null;
            }
        }
        return returnVal;
    }
}