import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.css']
})
export class ConfirmationModalComponent implements OnInit,IPromiseResult {
  result: Promise<any>;
  canModalActivate:boolean;
  modalTitleText:string;
  modalBodyText:string;
  resolve:any;
  constructor(private modalReference:BsModalRef) {
    this.result=new Promise((result)=>{
      this.resolve=result;
    });
   }

  ngOnInit() {
  }

  showConfirmationBox(title:string,body:string){
    this.modalTitleText=title;
    this.modalBodyText=body;
    this.canModalActivate=true;
  }

  onResponse(response:boolean){
    this.modalTitleText='';
    this.modalBodyText='';
    this.canModalActivate=false;
    this.modalReference.hide();
    this.resolve();
  }

  getResponse(){
    return this.result;
  }
}

export interface IPromiseResult {
  readonly result: Promise<any>;
}